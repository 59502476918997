import Vue, { DirectiveOptions } from 'vue'

import 'normalize.css'
import ElementUI from 'element-ui'
import SvgIcon from 'vue-svgicon'

import '@/styles/element-variables.scss'
import '@/styles/index.scss'

import App from '@/App.vue'
import store from '@/store'
import router from '@/router'
import i18n from '@/lang'
import '@/icons/components'
import '@/permission'
import '@/utils/error-log'
import '@/pwa/register-service-worker'
import * as directives from '@/directives'
import * as filters from '@/filters'

import VueMoment from 'vue-moment'
import vueDebounce from 'vue-debounce'
import Moment from 'moment'
import VueTrix from 'vue-trix'
import { getLanguage } from '@/utils/cookies'

Moment.locale(getLanguage() || 'fr')
Vue.use(VueMoment, { moment: Moment })

Vue.use(ElementUI, {
  size: 'medium',
  i18n: (key: string, value: string) => i18n.t(key, value)
})

Vue.use(vueDebounce, {
  listenTo: 'input'
})

Vue.use(VueTrix)

Vue.use(SvgIcon, {
  tagName: 'svg-icon',
  defaultWidth: '1em',
  defaultHeight: '1em'
})


// Register global directives
Object.keys(directives).forEach(key => {
  Vue.directive(key, (directives as { [key: string ]: DirectiveOptions })[key])
})

// Register global filter functions
Object.keys(filters).forEach(key => {
  Vue.filter(key, (filters as { [key: string ]: any })[key])
})

Vue.config.productionTip = false

Vue.filter('capitalize', function(value: any) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
